<template>
  <v-dialog v-model="show" max-width="650" :fullscreen="$vuetify.breakpoint.mobile">
    <v-card v-if="currentItem">
      <v-row no-gutters>
        <v-card-title>{{ isEditing ? 'Edit EULA' : 'Add new EULA' }}</v-card-title>
        <v-btn depressed v-if="$vuetify.breakpoint.mobile" class="ml-auto mr-4 mt-4" icon @click="show = false">
          <v-icon dark>mdi-close-thick</v-icon>
        </v-btn>
      </v-row>
      <v-card-text>
        <v-form v-model="isValid" ref="editItemRef">
          <v-row>
            <v-col cols="12">
              <v-text-field :rules="[rules.required]" v-model="currentItem.name" dense label="Name"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-file-input @change="fileSelected" v-model="uploadFile" :rules="isEditing ? [] : [rules.required]" dense label="EULA file" accept=".rtf" ></v-file-input>
            </v-col>
            <v-col cols="12">
              <v-checkbox v-model="currentItem.enabled" dense label="Enabled" />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-block d-md-flex">
        <v-btn depressed :block="$vuetify.breakpoint.mobile" @click="show = false" class="my-4">Cancel</v-btn>
        <v-btn
          depressed
          :block="$vuetify.breakpoint.mobile"
          @click="saveCurrent"
          color="success"
          class="ml-0 ml-md-4 my-4"
          :loading="isSaving"
          :disabled="!isValid"
        >
          <v-icon left>mdi-check</v-icon>
          {{ isEditing ? 'Update EULA' : 'Add EULA' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import apiService from '@/services/apiService.js';

export default {
  name: 'create-edit-eula',
  data: () => ({
    show: false,
    currentItem: {},
    uploadFile: null,
    isSaving: false,
    isValid: null,
    rules: {
      required: (value) => !!value || 'Field cannot be empty.',
    },
  }),

  computed: {
    isEditing() {
      return this.currentItem?.id ? true : false;
    },
  },
  methods: {
    addItem() {
      this.show = true;
      this.currentItem = {
        name: null,
        rtfText: null,
        enabled: true,
      };
    },
    editItem(eula) {
      this.show = true;
      this.currentItem = _.cloneDeep(eula);
    },
    async saveCurrent() {
      this.isSaving = true;
      await apiService
        .updateEula(this.currentItem)
        .then(() => {
          this.show = false;
          this.$emit('item-updated');
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
    async fileSelected(file) {
      if (!this.currentItem.name) {
        this.currentItem.name = file.name;
      }
      const reader = new FileReader();
      reader.onload = () => {
        this.currentItem.rtfText = reader.result;
      };
      reader.readAsText(file);
    },
  },
};
</script>
